<template>
  <section
    class="special-message-banner"
    :class="{
      'special-message-banner--guide': inside_user_guide,
    }"
  >
    <carousel
      :autoplay="true"
      :autoplayTimeout="nextSlideSpeed"
      :paginationEnabled="true"
      :navigationEnabled="true"
      :perPageCustom="[[300, 1]]"
      navigationNextLabel=" "
      navigationPrevLabel=" "
    >
      <slide
        v-for="(banner, index) in assembled_banners"
        :key="`${index}-slide`"
        class="special-message-banner__slide"
      >
        <section
          data-cy="special-message-banner-image"
          :class="{
            'special-message-banner--admin': admin_imported,
            'special-message-banner--admin-mobile': show_mobile
          }"
        >
          <a
            v-if="banner.link"
            :href="create_correct_link(banner.link, banner.external_link)"
            itemprop="banner link"
            target="_blank"
            :style="{
              pointerEvents: admin_imported ? 'none' : 'all'
            }"
          >
            <v-lazy-image
              :src="choose_img_to_show(banner)"
              :src-placeholder="choose_placeholder_img(banner)"
              :alt="banner.alt"
              class="special-message-banner__image"
              itemprop="image"
            />
          </a>
          <div v-else>
            <v-lazy-image
              :src="choose_img_to_show(banner)"
              :src-placeholder="choose_placeholder_img(banner)"
              :alt="banner.alt"
              class="special-message-banner__image"
              itemprop="image"
            />
            <div
              v-if="admin_imported && !admin_banners.special_message_banner"
              class="special-message-banner--admin-message md-title"
            >
              <p data-cy="special-message-text">
                {{ $translate("special_message_banner.demo_message") }}
              </p>
            </div>
          </div>
        </section>
      </slide>
    </carousel>
  </section>
</template>

<script>
import { mapState } from "vuex";
import VLazyImage from "v-lazy-image/v2/v-lazy-image.js";
import { CONTENT_STORE } from "../../../Admin/constants/others_constants";
import { CLIENT_STORE } from "../../constants/other";
import {
  default_image_src,
  SHARED_STORE,
} from "../../../Shared/constants/other";
import { Carousel, Slide } from "../../../Shared/components/slider/index"
import create_correct_product_url from "../../methods/create_correct_product_url";
import { COUPLE, MULTI, SINGLE } from "../../../../data/product_variety_types";
import check_if_user_in_store_preview from "../../../Shared/methods/check_if_user_in_store_preview";

export default {
  props: {
    admin_imported: {
      type: Boolean,
      required: false,
      default: false,
    },
    show_mobile: {
      type: Boolean,
      required: false,
      default: false,
    },
    inside_user_guide: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    VLazyImage,
    Carousel,
    Slide
  },
  data() {
    return {
      nextSlideSpeed: 5000
    }
  },
  computed: {
    ...mapState(SHARED_STORE, ["is_mobile_resolution", "store_id"]),
    ...mapState(CLIENT_STORE, ["banners", "user_is_using_fast_connection", "category_of_product_variety"]),
    ...mapState(CONTENT_STORE, {
      admin_banners: "banners",
    }),
    special_banner() {
      return (
        (this.admin_banners && this.admin_banners.special_message_banner) ||
        this.banners.special_message_banner ||
        (this.admin_imported && { src: default_image_src })
      );
    },
    assembled_banners() {
      if (!this.special_banner) return []

      const is_banner_array = this.special_banner?.constructor === Array

      return (is_banner_array ?
        this.special_banner :
        [this.special_banner]).filter(({ show_on_single_and_couple }) =>
          (
            show_on_single_and_couple &&
            (
              this.category_of_product_variety === COUPLE ||
              this.category_of_product_variety === SINGLE
            )
          ) ||
          this.category_of_product_variety === MULTI
        )
    },
  },
  methods: {
    create_correct_product_url,
    create_correct_link(link, external_link) {
      const updated_link = check_if_user_in_store_preview() ? `/${this.store_id}${link}` : link

      return external_link ? 'https://' + link.replace('https://', '').replace('http://', '') : updated_link
    },
    choose_placeholder_img(banner) {
      if (this.is_mobile_resolution && banner.mobile_image)
        return banner.mobile_image;

      return banner.mobile_image || banner.src;
    },
    /**
     *
     */
    choose_img_to_show(banner) {
      if (
        (this.is_mobile_resolution || !this.user_is_using_fast_connection) &&
        banner.mobile_image
      )
        return banner.mobile_image;

      return banner.src;
    },
  },
};
</script>

<style lang="scss">
@use "../../../../styles/_global-constants" as *;

.special-message-banner {
  position: relative;
  margin: 0 0 40px;

  @media (max-width: $tablet) {
    width: calc(100% + 40px);
    padding-left: 0;
    padding-right: 0;
    margin: 0 0 60px;
    transform: translateX(-20px);

    &--guide {
      width: 100%;
      transform: none;
      margin: 0 0 40px;
    }
  }

  .VueCarousel {
    width: 100%;
  }

  &--admin {
    padding-left: 0;
    padding-right: 0;

    img {
      height: 500px !important;
    }

    &-mobile {
      img {
        height: 300px !important;
      }
    }

    &-message {
      position: absolute;
      top: 0;
      left: 0;
      background: $transparency-white-color;
      width: 100%;
      height: 100%;
      text-align: center;

      p {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $pure-black;
        margin: 0;
      }
    }
  }

  &__slide {
    position: relative;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    box-sizing: border-box;
    text-align: center;

    &:first-child {
      border-left: 0;
    }

    img {
      width: 100%;
      height: 500px;
      object-position: center;
      object-fit: cover;
      
      @media (max-width: $tablet) {
        height: 400px;
      }
      
      @media (max-width: $tablet--small) {
        height: 300px;
      }

      @media (max-width: $mobile) {
        height: 200px;
      }
    }
  }
}
</style>
